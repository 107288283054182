import type { FunctionComponent } from 'react'
import { useCallback, useContext, useMemo, useState } from 'react'
import { TypographyV2 } from '@which/seatbelt'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/SolidPadlock'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { SVGUtil } from '../../../../../../shared/components/SVGUtil'
import { SVGName } from '../../../../../../shared/components/SVGUtil/svgs'
import { StarRating } from '../../../../product-page/components/StarRating'
import { ComparisonTableContext } from '../../../ComparisonTableContext'
import { Product } from '../ProductDetails/Product'
import styles from './ComparisonTableV2.module.scss'

export const ComparisonTableV2: FunctionComponent<ComparisonTablePageProps> = ({
  taxonomySlug,
}) => {
  const {
    state: { productDetails, tableData },
  } = useContext(ComparisonTableContext)

  const hasOneProductBadge = useMemo(
    () => productDetails?.some((productDetail) => productDetail.badges.length),
    [productDetails]
  )

  const [productIndexesForComparison, setProductIndexesForComparison] = useState<number[]>(
    Array.from(productDetails?.keys())?.splice(0, MAX_PRODUCTS_TO_COMPARE)
  )

  const tooltipOpenHandler = useCallback(() => {
    dynamicGa4DataLayerPush({
      eventCategory: 'Product Compare',
      eventAction: 'Tooltip',
      eventLabel: `${taxonomySlug} - Product Card - Test Score`,
    })
  }, [taxonomySlug])

  if (!productDetails?.length || !tableData?.length) {
    return null
  }

  const changeProductHandler = (
    event: React.ChangeEvent<HTMLSelectElement>,
    indexToReplace: number
  ) => {
    const currentTarget = event?.currentTarget

    if (currentTarget) {
      const newIndex = parseInt(currentTarget?.value)

      dynamicGa4DataLayerPush({
        utagid: 'RS2947DP01',
        event: 'click_compare',
        action_group: 'switch product',
        item_group: 'product compare',
        item_text: currentTarget[currentTarget.selectedIndex].textContent ?? undefined,
      })

      setProductIndexesForComparison((currentIndexes) =>
        currentIndexes.map((currentIndex, i) => (i === indexToReplace ? newIndex : currentIndex))
      )
    }
  }

  const selectClickHandler = (event: React.MouseEvent<HTMLSelectElement>) => {
    const currentTarget = event?.currentTarget

    if (currentTarget) {
      dynamicGa4DataLayerPush({
        utagid: 'RS2947DP01',
        event: 'click_compare',
        action_group: 'switch product',
        item_group: 'product compare',
        item_text: 'change product',
      })
    }
  }

  const productDetailsForComparison = productIndexesForComparison.map((i) => productDetails[i])

  const tableDataForComparison = tableData.map((tableData) => {
    return {
      ...tableData,
      rows: tableData.rows.map((row) => {
        return {
          ...row,
          rowValues: productIndexesForComparison.map((i) => row.rowValues[i]),
        }
      }),
    }
  })

  return (
    <div className={styles.tableWrapper} data-testid="comparison-table-v2">
      <section className={styles.header}>
        <ul>
          <li className={styles.filler} />
          {productDetailsForComparison.map((productDetail, productIndex) => (
            <li key={`header-${productDetail?.businessKey}`}>
              <TypographyV2 textStyle="sb-text-body-default-strong" data-testid="manufacturer-name">
                {productDetail?.manufacturer?.name}
              </TypographyV2>
              <TypographyV2 textStyle="sb-text-body-default-regular" data-testid="model">
                {productDetail?.model}
              </TypographyV2>
              {productDetails.length > MAX_PRODUCTS_TO_COMPARE && (
                <div className={styles.selectWrapper}>
                  <select
                    name={`select-${productDetail?.businessKey}`}
                    aria-label="Change product"
                    data-testid={`select-${productDetail?.businessKey}`}
                    onChange={(event) => changeProductHandler(event, productIndex)}
                    onClick={(event) => selectClickHandler(event)}
                  >
                    <option>Change product</option>
                    {productDetails.map(
                      (innerProductDetail, i) =>
                        !productIndexesForComparison.includes(i) && (
                          <option
                            value={i}
                            key={`select-${productIndex}-option-${innerProductDetail?.businessKey}`}
                          >
                            {innerProductDetail?.manufacturer?.name} {innerProductDetail?.model}
                          </option>
                        )
                    )}
                  </select>
                  <SVGUtil
                    name={SVGName.Change}
                    width={15}
                    height={15}
                    viewBox="0 0 15 15"
                    title="Change product icon"
                    className={styles.icon}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      </section>

      <section className={styles.productDetails}>
        <div className={styles.filler} />
        {productDetailsForComparison.map((productDetail) => (
          <Product
            key={productDetail.businessKey}
            hasOneProductBadge={hasOneProductBadge}
            taxonomySlug={taxonomySlug}
            tooltipOpenHandler={tooltipOpenHandler}
            tag="div"
            {...productDetail}
          />
        ))}
      </section>

      {tableDataForComparison?.map(({ label: sectionLabel, rows, dataType }, sectionIndex) => (
        <section key={`${sectionLabel}-${sectionIndex}`}>
          {sectionLabel && (
            <TypographyV2 textStyle="sb-text-body-default-strong">{sectionLabel}</TypographyV2>
          )}
          {rows.map(({ rowLabel, rowValues }, rowIndex) => {
            return (
              <div
                className={styles.productFeatureRow}
                key={`${rowLabel}-${rowIndex}`}
                data-testid={dataType === 'test-result' ? 'test-results-row' : 'tech-spec-row'}
              >
                <div className={styles.rowLabel}>
                  <TypographyV2 textStyle="sb-text-interface-body-small-regular" tag="span">
                    {rowLabel}
                  </TypographyV2>
                </div>
                {rowValues.map(({ value }, valIndex) => (
                  <div className={styles.tableDataRowValue} key={`${value}-${valIndex}`}>
                    {getValueElement(value, dataType)}
                  </div>
                ))}
              </div>
            )
          })}
        </section>
      ))}
    </div>
  )
}

const getValueElement = (value, dataType) => {
  if (value === '') {
    return (
      <SolidPadlockIcon
        className={styles.padlock}
        data-testid="padlock-icon"
        width={15}
        height={15}
        viewBox="0 0 15 15"
      />
    )
  }

  return (
    <TypographyV2 textStyle="sb-text-interface-body-small-regular" tag="span">
      {dataType === 'test-result' ? <StarRating value={value} /> : value}
    </TypographyV2>
  )
}

///////// IMPLEMENTATION /////////

export type ComparisonTablePageProps = {
  taxonomySlug: string
}

// This will be a state when the same component is used for desktop
export const MAX_PRODUCTS_TO_COMPARE = 2
