import type { FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { ComparisonTable } from '../../../../../../generated/frontend'
import { ComparisonTableContext } from '../../../ComparisonTableContext'
import { totalProductColumns } from '../ComparisonTable'
import comparisonTableStyles from '../ComparisonTable.module.scss'
import { FillerCells as EmptyTableData } from '../FillerCells/FillerCells'
import { Product } from './Product'
import styles from './ProductDetails.module.scss'

export const ProductDetails: FunctionComponent<ProductDetailProps> = ({ taxonomySlug }) => {
  const {
    state: { productDetails },
  } = useContext(ComparisonTableContext)

  if (!productDetails?.length) {
    return null
  }

  const hasOneProductBadge = productDetails.some((productDetail) => productDetail.badges.length)

  const tooltipOpenHandler = () => {
    dynamicGa4DataLayerPush({
      eventCategory: 'Product Compare',
      eventAction: 'Tooltip',
      eventLabel: `${taxonomySlug} - Product Card - Test Score`,
    })
  }

  return (
    <tr className={styles.row} data-testid="product-detail-row">
      {/* Will be used for instructional messaging when the table is being refined */}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <td
        className={classnames(
          styles.tableDataInstructions,
          comparisonTableStyles.stickyFirstColumn
        )}
      ></td>

      {productDetails.map((productDetail) => (
        <Product
          key={productDetail.businessKey}
          hasOneProductBadge={hasOneProductBadge}
          taxonomySlug={taxonomySlug}
          tooltipOpenHandler={tooltipOpenHandler}
          {...productDetail}
        />
      ))}

      <EmptyTableData
        className={classnames(styles.tableData, comparisonTableStyles.tableDataRowValue)}
        count={totalProductColumns - productDetails.length}
      />
    </tr>
  )
}

export type ProductDetailProps = {
  taxonomySlug: ComparisonTable['taxonomySlug']
}
